
// Vue
import {Options, Vue} from "vue-class-component";

// Store
import store from "@/store";

// Models
import {AppTransactionIndex} from "@/models/app/transaction";
import {AppInvoicePaymentCreateIncomingInvoicePaymentIndex} from "@/models/app/invoice-payment/create-incoming-invoice-payment";
import {AppIncomingInvoiceIndex} from "@/models/app/incoming-invoice";
import {ResponseIndex} from "@/models/response";

// Components
import {
  ElDialog,
  ElRow,
  ElCol,
  ElForm,
  ElFormItem,
  ElInput,
  ElSelect,
  ElOption,
  ElDatePicker,
  ElButton,
} from "element-plus";

// Services
import {getRequest} from "@/services/api/request";
import {postRequest} from "@/services/api/request";
import {getCurrencyFormatHRK, getCurrencyFormatEUR, getInvoiceNumberInYear} from "@/services/app/data";
import {getTranslation} from "@/services/app/translation";
import {AppInvoicePaymentIndex} from "@/models/app/invoice-payment";

@Options({
  props: [
    "transaction",
  ],
  components: {
    ElDialog,
    ElRow,
    ElCol,
    ElForm,
    ElFormItem,
    ElInput,
    ElSelect,
    ElOption,
    ElDatePicker,
    ElButton,
  },
  watch: {
    'transaction'(): void {
      this.resetFormData();
    },
    'formData.incoming_invoice_id'(): void {
      if (this.formData.incoming_invoice_id !== "") {
        this.incomingInvoice = this.incomingInvoices.find(item => item.id === this.formData.incoming_invoice_id);

        if (this.incomingInvoice !== undefined) {
          this.formData.content.amount.eur = Number(parseFloat(this.incomingInvoice.content.amount.eur) - parseFloat(this.incomingInvoice.content.paidAmount.eur)).toFixed(2);
        }
      }
    },
    'formData.content.amount.eur'(): void {
      this.formData.content.amount.hrk = Number((this.formData.content.amount.eur) * 7.53450).toFixed(2);
    },
  },
})
export default class AppAdministratorMasterDataPartnersDetailsTransactionsFormCreateIncomingInvoicePaymentIndexVue extends Vue {
  isLoading = false;
  isDialogVisible = false;

  transaction: AppTransactionIndex = new AppTransactionIndex();
  incomingInvoice: AppIncomingInvoiceIndex = new AppIncomingInvoiceIndex();
  incomingInvoices: Array<AppIncomingInvoiceIndex> = [];

  formData: AppInvoicePaymentCreateIncomingInvoicePaymentIndex = new AppInvoicePaymentCreateIncomingInvoicePaymentIndex();
  formDataRules = {
    incoming_invoice_id: [
      {
        required: true,
        trigger: "blur",
      },
    ],
    content: {
      date: [
        {
          required: true,
          trigger: "blur",
        },
      ],
      amount: {
        hrk: [
          {
            required: true,
            trigger: "blur",
          },
        ],
        eur: [
          {
            required: true,
            trigger: "blur",
          },
        ],
      },
    },
  };

  get translation(): any {
    return getTranslation([
      "amount",
      "choose",
      "date",
      "difference",
      "incomingInvoice",
      "paidAmount",
      "pay",
      "payAndContinue",
      "remaining",
      "spent",
      "titlePlaceholderAmount",
      "titlePlaceholderDate",
      "transaction",
    ]);
  }

  get isIncomingInvoiceSet(): boolean {
    return this.formData.incoming_invoice_id !== "";
  }

  get isFormAmountGreaterThanAllowedIncomingInvoiceAmount(): boolean {
    return Number(this.formData.content.amount.eur) > ((Number(this.incomingInvoice.content.amount.eur) - Number(this.incomingInvoice.content.paidAmount.eur)) + 0.01);
  }

  get isFormAmountGreaterThanAllowedMaximumAmount(): boolean {
    return Number(this.formData.content.amount.eur) > (this.maximumAmount + 0.01);
  }

  get isPaymentDisabled(): boolean {
    if (this.isIncomingInvoiceSet) {
      return this.isFormAmountGreaterThanAllowedIncomingInvoiceAmount || this.isFormAmountGreaterThanAllowedMaximumAmount;
    }

    return true;
  }

  get isPayAndContinue(): boolean {
    return this.incomingInvoices.length > 1;
  }

  get maximumAmount(): number {
    let invoicePaymentsAmount = 0;
    let transactionAmount = parseFloat(this.transaction.content.amount.eur);

    switch (this.transaction.payment_type.content.title) {
      case "D":
        this.transaction.invoice_payments.forEach((invoicePayment: AppInvoicePaymentIndex) => {
          if (invoicePayment.incoming_invoice_id) {
            invoicePaymentsAmount = invoicePaymentsAmount + parseFloat(invoicePayment.content.amount.eur);
          }
        });
        break;
      case "P":
        this.transaction.invoice_payments.forEach((invoicePayment: AppInvoicePaymentIndex) => {
          if (invoicePayment.outgoing_invoice_id) {
            invoicePaymentsAmount = invoicePaymentsAmount + parseFloat(invoicePayment.content.amount.eur);
          }
        });
        break;
    }

    return this.getDifferenceAmount(transactionAmount, invoicePaymentsAmount);
  }

  get remainingAmount(): string {
    return this.maximumAmount.toFixed(2);
  }

  get spentAmount(): string {
    return (parseFloat(this.transaction.content.amount.eur) - this.maximumAmount).toFixed(2);
  }

  getDifferenceAmount(transactionAmount: number, invoicePaymentsAmount: number): number {
    const differenceAmount = Math.round((transactionAmount - invoicePaymentsAmount) * 100) / 100;

    if (differenceAmount.toFixed(2) === "-0.01") {
      return 0;
    }

    if (differenceAmount.toFixed(2) === "-0.00") {
      return 0;
    }

    return differenceAmount;
  }

  getCurrencyFormatHRK(data: string): string {
    return getCurrencyFormatHRK(data);
  }

  getCurrencyFormatEUR(data: string): string {
    return getCurrencyFormatEUR(data);
  }

  getInvoiceNumberInYear(number: string, year: string): string {
    return getInvoiceNumberInYear(number, year);
  }

  async getIncomingInvoicesByPartner(): Promise<void> {
    this.isLoading = true;
    await getRequest({
      uri: `common/incoming-invoices/by-partner`,
      formData: {
        organization_id: store.getters.getOrganizationMember.organization_id,
        partner_id: this.transaction.partner_id,
      },
      isProtected: true,
    }).then((r: ResponseIndex) => {
      if (r.data) {
        this.incomingInvoices = r.data;
      }
    });
    this.isLoading = false;
  }

  $refs!: {
    formComponent: HTMLFormElement;
  };

  validateForm(): void {
    this.$refs.formComponent.validate((response: any) => {
      if (response) {
        this.submitForm();
      }
    });
  }

  async submitForm(): Promise<void> {
    this.isLoading = true;
    await postRequest({
      uri: "/common/invoice-payment/create-incoming-invoice-payment",
      formData: this.formData,
      isProtected: true,
      isSuccessNotificationVisible: true,
      isErrorNotificationVisible: true,
    }).then((r: ResponseIndex) => {
      if (r.status === "success") {
        this.$emit('getTransactions');
        this.resetFormData();

        if (this.isPayAndContinue) {
          this.getIncomingInvoicesByPartner();
          this.setFormData();
        } else {
          this.isDialogVisible = false;
        }
      }
    });
    this.isLoading = false;
  }

  setFormData(): void {
    this.formData.payment_type_id = this.transaction.payment_type_id;
    this.formData.transaction_id = this.transaction.id;
    this.formData.content.date = this.transaction.excerpt.content.date;
  }

  resetFormData(): void {
    this.formData = new AppInvoicePaymentCreateIncomingInvoicePaymentIndex();
  }

  async updated(): Promise<void> {
    await this.getIncomingInvoicesByPartner();
    this.setFormData();
  }
}
