
// Vue
import {Options, Vue} from "vue-class-component";

// Store
import store from "@/store/index";

// Models
import {AppTransactionIndex} from "@/models/app/transaction";
import {AppInvoicePaymentIndex} from "@/models/app/invoice-payment";
import {AppPartnerIndex} from "@/models/app/partner";
import {ResponseIndex} from "@/models/response";

// Components
import {
  ElTable,
  ElTableColumn,
  ElForm,
  ElFormItem,
  ElSelect,
  ElOption,
  ElButton,
} from "element-plus";
import AppAdministratorInvoicesExcerptsDetailsTransactionsFormLookupIncomingInvoicePaymentIndexVue from "@/views/app/administrator/invoices/excerpts/details/transactions/form/lookup-incoming-invoice-payment.vue";
import AppAdministratorInvoicesExcerptsDetailsTransactionsFormLookupOutgoingInvoicePaymentIndexVue from "@/views/app/administrator/invoices/excerpts/details/transactions/form/lookup-outgoing-invoice-payment.vue";
import AppAdministratorInvoicesExcerptsDetailsTransactionsFormCreateIncomingInvoicePaymentIndexVue from "@/views/app/administrator/invoices/excerpts/details/transactions/form/create-incoming-invoice-payment.vue";
import AppAdministratorInvoicesExcerptsDetailsTransactionsFormCreateOutgoingInvoicePaymentIndexVue from "@/views/app/administrator/invoices/excerpts/details/transactions/form/create-outgoing-invoice-payment.vue";
import AppAdministratorInvoicesExcerptsDetailsTransactionsFormCreateIncomingInvoiceCompensationPaymentIndexVue from "@/views/app/administrator/invoices/excerpts/details/transactions/form/create-incoming-invoice-compensation-payment.vue";
import AppAdministratorInvoicesExcerptsDetailsTransactionsFormCreateOutgoingInvoiceCompensationPaymentIndexVue from "@/views/app/administrator/invoices/excerpts/details/transactions/form/create-outgoing-invoice-compensation-payment.vue";
import AppAdministratorInvoicesExcerptsDetailsTransactionsFormUpdatePartnerIndexVue from "@/views/app/administrator/invoices/excerpts/details/transactions/form/update-partner.vue";
import LoadingIndexVue from "@/components/loading.vue";

// Services
import {getRequest} from "@/services/api/request";
import {getDateFormat} from "@/services/app/date";
import {getCurrencyFormatHRK, getCurrencyFormatEUR} from "@/services/app/data";
import {getTranslation} from "@/services/app/translation";

@Options({
  components: {
    ElTable,
    ElTableColumn,
    ElForm,
    ElFormItem,
    ElSelect,
    ElOption,
    ElButton,
    AppAdministratorInvoicesExcerptsDetailsTransactionsFormLookupIncomingInvoicePaymentIndexVue,
    AppAdministratorInvoicesExcerptsDetailsTransactionsFormLookupOutgoingInvoicePaymentIndexVue,
    AppAdministratorInvoicesExcerptsDetailsTransactionsFormCreateIncomingInvoicePaymentIndexVue,
    AppAdministratorInvoicesExcerptsDetailsTransactionsFormCreateOutgoingInvoicePaymentIndexVue,
    AppAdministratorInvoicesExcerptsDetailsTransactionsFormCreateIncomingInvoiceCompensationPaymentIndexVue,
    AppAdministratorInvoicesExcerptsDetailsTransactionsFormCreateOutgoingInvoiceCompensationPaymentIndexVue,
    AppAdministratorInvoicesExcerptsDetailsTransactionsFormUpdatePartnerIndexVue,
    LoadingIndexVue,
  },
})
export default class AppAdministratorInvoicesExcerptsDetailsTransactionsIndexVue extends Vue {
  isLoading = false;
  isDialogVisible = false;

  transaction: AppTransactionIndex = new AppTransactionIndex();
  transactions: Array<AppTransactionIndex> = [];
  invoicePayments: Array<AppInvoicePaymentIndex> = [];
  partners: Array<AppPartnerIndex> = [];

  formData: any = [];

  $refs!: {
    AppAdministratorInvoicesExcerptsDetailsTransactionsFormLookupIncomingInvoicePaymentIndexVue: any;
    AppAdministratorInvoicesExcerptsDetailsTransactionsFormLookupOutgoingInvoicePaymentIndexVue: any;
    AppAdministratorInvoicesExcerptsDetailsTransactionsFormCreateIncomingInvoicePaymentIndexVue: any;
    AppAdministratorInvoicesExcerptsDetailsTransactionsFormCreateOutgoingInvoicePaymentIndexVue: any;
    AppAdministratorInvoicesExcerptsDetailsTransactionsFormCreateIncomingInvoiceCompensationPaymentIndexVue: any;
    AppAdministratorInvoicesExcerptsDetailsTransactionsFormCreateOutgoingInvoiceCompensationPaymentIndexVue: any;
    AppAdministratorInvoicesExcerptsDetailsTransactionsFormUpdatePartnerIndexVue: any;
  };

  get isDataLoaded(): boolean {
    return this.transactions.length > 0 && this.isLoading === false;
  }

  get isDataLoadedAndEmpty(): boolean {
    return this.transactions.length === 0 && this.isLoading === false;
  }

  get translation(): any {
    return getTranslation([
      "action",
      "bank",
      "amount",
      "change",
      "compensation",
      "date",
      "excerpt",
      "information",
      "invoicePayment",
      "invoicePayments",
      "noData",
      "transaction",
      "transactions",
      "partner",
      "pay",
      "paymentPurpose",
      "paymentType",
      "remaining",
      "review",
      "undefined",
    ]);
  }

  getDateFormat(data: string): string {
    return getDateFormat(data);
  }

  getCurrencyFormatHRK(data: string): string {
    return getCurrencyFormatHRK(data);
  }

  getCurrencyFormatEUR(data: string): string {
    return getCurrencyFormatEUR(data);
  }

  getMaximumAmount(transaction: AppTransactionIndex): number {
    let invoicePaymentsAmount = 0;
    let transactionAmount = parseFloat(transaction.content.amount.eur);

    switch (transaction.payment_type.content.title) {
      case "D":
        transaction.invoice_payments.forEach((invoicePayment: AppInvoicePaymentIndex) => {
          if (invoicePayment.incoming_invoice_id) {
            invoicePaymentsAmount = invoicePaymentsAmount + parseFloat(invoicePayment.content.amount.eur);
          }
        });
        break;
      case "P":
        transaction.invoice_payments.forEach((invoicePayment: AppInvoicePaymentIndex) => {
          if (invoicePayment.outgoing_invoice_id) {
            invoicePaymentsAmount = invoicePaymentsAmount + parseFloat(invoicePayment.content.amount.eur);
          }
        });
        break;
    }

    return this.getDifferenceAmount(transactionAmount, invoicePaymentsAmount);
  }

  getDifferenceAmount(transactionAmount: number, invoicePaymentsAmount: number): number {
    const differenceAmount = Math.round((transactionAmount - invoicePaymentsAmount) * 100) / 100;

    if (differenceAmount.toFixed(2) === "-0.01") {
      return 0;
    }

    if (differenceAmount.toFixed(2) === "-0.00") {
      return 0;
    }

    return differenceAmount;
  }

  getRemainingAmount(transaction: AppTransactionIndex): string {
    return this.getMaximumAmount(transaction).toFixed(2);
  }

  isTransactionSpent(transaction: AppTransactionIndex): boolean {
    return this.getMaximumAmount(transaction) <= 0;
  }

  async getTransactions(): Promise<void> {
    this.isLoading = true;
    await getRequest({
      uri: `/common/transactions`,
      formData: {
        organization_id: store.getters.getOrganizationMember.organization_id,
        excerpt_id: this.$route.params.id,
      },
      isProtected: true,
    }).then((r: ResponseIndex) => {
      if (r.data) {
        this.transactions = r.data;

        setTimeout(() => {
          if (this.transaction.id !== "") {
            this.transaction = this.transactions.find((item: AppTransactionIndex) => item.id === this.transaction.id);
            const transactionEl = document.getElementById(`Transaction-${this.transaction.id}`);

            if (transactionEl) {
              transactionEl.scrollIntoView();
            }
          }
        }, 100);

        this.$emit("setRemainingAmountTotal", this.setRemainingAmountTotal());
      }
    });
    this.isLoading = false;
  }

  async getPartners(): Promise<void> {
    this.isLoading = true;
    await getRequest({
      uri: `/common/partners`,
      formData: {
        organization_id: store.getters.getOrganizationMember.organization_id,
        pagination: false,
      },
      isProtected: true,
    }).then((r: ResponseIndex) => {
      if (r.data) {
        this.partners = r.data;
      }
    });
    this.isLoading = false;
  }

  setRemainingAmountTotal(): number {
    let totalRemainingAmount = 0;

    this.transactions.forEach((transaction: AppTransactionIndex) => {
      totalRemainingAmount += this.getMaximumAmount(transaction);
    });

    return totalRemainingAmount;
  }

  setDialogLookupIncomingInvoicePayment(data: Array<AppInvoicePaymentIndex>) {
    this.invoicePayments = data;
    this.$refs.AppAdministratorInvoicesExcerptsDetailsTransactionsFormLookupIncomingInvoicePaymentIndexVue.isDialogVisible = true;
  }

  setDialogLookupOutgoingInvoicePayment(data: Array<AppInvoicePaymentIndex>) {
    this.invoicePayments = data;
    this.$refs.AppAdministratorInvoicesExcerptsDetailsTransactionsFormLookupOutgoingInvoicePaymentIndexVue.isDialogVisible = true;
  }

  setDialogCreateIncomingInvoicePayment(data: AppTransactionIndex) {
    this.transaction = data;
    this.$refs.AppAdministratorInvoicesExcerptsDetailsTransactionsFormCreateIncomingInvoicePaymentIndexVue.isDialogVisible = true;
  }

  setDialogCreateOutgoingInvoicePayment(data: AppTransactionIndex) {
    this.transaction = data;
    this.$refs.AppAdministratorInvoicesExcerptsDetailsTransactionsFormCreateOutgoingInvoicePaymentIndexVue.isDialogVisible = true;
  }

  setDialogCreateIncomingInvoiceCompensationPayment(data: AppTransactionIndex) {
    this.transaction = data;
    this.$refs.AppAdministratorInvoicesExcerptsDetailsTransactionsFormCreateIncomingInvoiceCompensationPaymentIndexVue.isDialogVisible = true;
  }

  setDialogCreateOutgoingInvoiceCompensationPayment(data: AppTransactionIndex) {
    this.transaction = data;
    this.$refs.AppAdministratorInvoicesExcerptsDetailsTransactionsFormCreateOutgoingInvoiceCompensationPaymentIndexVue.isDialogVisible = true;
  }

  setDialogUpdatePartner(data: AppTransactionIndex) {
    this.transaction = data;
    this.$refs.AppAdministratorInvoicesExcerptsDetailsTransactionsFormUpdatePartnerIndexVue.isDialogVisible = true;
  }

  async created(): Promise<void> {
    await this.getTransactions();
    await this.getPartners();
  }
}
