
// Vue
import {Options, Vue} from "vue-class-component";

// Store
import store from "@/store/index";

// Models
import {AppExcerptIndex} from "@/models/app/excerpt";
import {ResponseIndex} from "@/models/response";

// Components
import AppAdministratorInvoicesExcerptsDetailsInformationFormRemoveIndexVue from "@/views/app/administrator/invoices/excerpts/details/information/form/remove.vue";
import LoadingIndexVue from "@/components/loading.vue";

// Services
import {getRequest} from "@/services/api/request";
import {getCurrencyFormatHRK, getCurrencyFormatEUR} from "@/services/app/data";
import {getDateFormat, getDateYearFormat} from "@/services/app/date";
import {getTranslation} from "@/services/app/translation";

@Options({
  components: {
    AppAdministratorInvoicesExcerptsDetailsInformationFormRemoveIndexVue,
    LoadingIndexVue,
  },
})
export default class AppAdministratorInvoicesExcerptsDetailsInformationIndexVue extends Vue {
  isLoading = false;

  remainingAmountTotal = "0.00";

  excerpt: AppExcerptIndex = new AppExcerptIndex();

  get isDataLoaded(): boolean {
    return this.excerpt && this.isLoading === false;
  }

  get translation(): any {
    return getTranslation([
      "bank",
      "balance",
      "date",
      "excerpt",
      "initialBalance",
      "totalDebt",
      "totalDemand",
    ]);
  }

  getCurrencyFormatHRK(data: string): string {
    return getCurrencyFormatHRK(data);
  }

  getCurrencyFormatEUR(data: string): string {
    return getCurrencyFormatEUR(data);
  }

  getDateFormat(data: string): string {
    return getDateFormat(data);
  }

  getDateYearFormat(data: string): string {
    return getDateYearFormat(data);
  }

  async getExcerpt(): Promise<void> {
    this.isLoading = true;
    await getRequest({
      uri: `/common/excerpt`,
      formData: {
        organization_id: store.getters.getOrganizationMember.organization_id,
        id: this.$route.params.id,
      },
      isProtected: true,
    }).then((r: ResponseIndex) => {
      if (r.data) {
        this.excerpt = r.data;
      }
    });
    this.isLoading = false;
  }

  setRemainingAmountTotal(remainingAmountTotal: number): void {
    this.remainingAmountTotal = remainingAmountTotal.toFixed(2);
  }

  async created(): Promise<void> {
    await this.getExcerpt();
  }
}
