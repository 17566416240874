
// Vue
import {Options, Vue} from "vue-class-component";

// Store
import store from "@/store";

// Models
import {AppTransactionIndex} from "@/models/app/transaction";
import {ResponseIndex} from "@/models/response";

// Components
import {
  ElDialog,
  ElForm,
  ElFormItem,
  ElSelect,
  ElOption,
  ElButton,
} from "element-plus";

// Services
import {putRequest} from "@/services/api/request";
import {getTranslation} from "@/services/app/translation";

@Options({
  props: [
    "transaction",
    "partners",
  ],
  components: {
    ElDialog,
    ElForm,
    ElFormItem,
    ElSelect,
    ElOption,
    ElButton,
  },
})
export default class AppAdministratorMasterDataPartnersDetailsTransactionsFormUpdatePartnerIndexVue extends Vue {
  isLoading = false;
  isDialogVisible = false;

  transaction: AppTransactionIndex = new AppTransactionIndex();

  formData: AppTransactionIndex = new AppTransactionIndex();

  get translation(): any {
    return getTranslation([
      "choose",
      "edit",
      "save",
      "partners",
    ]);
  }

  $refs!: {
    formComponent: HTMLFormElement;
  };

  validateForm(): void {
    this.$refs.formComponent.validate((response: any) => {
      if (response) {
        this.submitForm();
      }
    });
  }

  async submitForm(): Promise<void> {
    this.isLoading = true;
    await putRequest({
      uri: "/common/transaction/update",
      formData: this.formData,
      isProtected: true,
      isSuccessNotificationVisible: true,
      isErrorNotificationVisible: true,
    }).then((r: ResponseIndex) => {
      if (r.status === "success") {
        this.$emit("getTransactions");
        this.isDialogVisible = false;
        this.resetFormData();
      }
    });
    this.isLoading = false;
  }

  setFormData(): void {
    this.formData = this.transaction;
  }

  resetFormData(): void {
    this.formData = new AppTransactionIndex();
  }

  async updated(): Promise<void> {
    this.setFormData();
  }
}
