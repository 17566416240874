
// Vue
import {Options, Vue} from "vue-class-component";

// Components
import {
  ElRow,
  ElCol,
} from "element-plus";
import AdministratorNavigationVue from "@/components/administrator/navigation.vue";
import AdministratorNavigationInvoicesVue from "@/components/administrator/navigation-invoices.vue";
import AppAdministratorInvoicesExcerptsDetailsInformationIndexVue from "@/views/app/administrator/invoices/excerpts/details/information/index.vue";
import AppAdministratorInvoicesExcerptsDetailsTransactionsIndexVue from "@/views/app/administrator/invoices/excerpts/details/transactions/index.vue";

@Options({
  components: {
    ElRow,
    ElCol,
    AdministratorNavigationVue,
    AdministratorNavigationInvoicesVue,
    AppAdministratorInvoicesExcerptsDetailsInformationIndexVue,
    AppAdministratorInvoicesExcerptsDetailsTransactionsIndexVue,
  },
})
export default class AppAdministratorInvoicesExcerptsDetailsIndexVue extends Vue {
  $refs!: {
    AppAdministratorInvoicesExcerptsDetailsInformationIndexVue: any;
  };

  setRemainingAmountTotal(remainingAmountTotal: number): void {
    this.$refs.AppAdministratorInvoicesExcerptsDetailsInformationIndexVue.setRemainingAmountTotal(remainingAmountTotal);
  }
}
